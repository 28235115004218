import "./pageBanner.scss"
import React from "react";
import PropTypes from "prop-types";
import PageBannerImage from "../../../components/page-banner-image";
import PageTitle from "../../../components/page-title";

const PageBanner = ({ title, excerpt, image }) => {
    return (
        <div className="pageBannerSection position-relative">
            <img className="banner-shape" src={process.env.PUBLIC_URL + "/images/banner/shape_dual_padel.png"} alt="shape" />
            <div className="pageBannerContainer">
                <div className="row">
                    <div className="col-md-6 mb-md-3">
                        <PageTitle title={title} excerpt={excerpt} />
                    </div>

                    <div className="col-md-6 mt-7 mt-md-0">

                        {image ? <PageBannerImage image={image} /> : <></>}

                    </div>
                </div>
            </div>
        </div>
    );
};

PageBanner.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
    image: PropTypes.string,
};

export default PageBanner;
