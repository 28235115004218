import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import "./classification.scss"
const ClasificationGroups = ({ title, categories }) => {
    const [expandCategory, setExpandCategory] = useState("")

    useEffect(() => {
        setExpandCategory(localStorage.getItem("expandCategory") || "")
        const element = document.getElementById(localStorage.getItem("expandCategory") || null);
        if (element) {
            const yOffset = -120;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, []);

    const handleExpandCategory = (value) => {
        if (value === expandCategory) {
            setExpandCategory("")
            localStorage.setItem("expandCategory", "")
        } else {
            setExpandCategory(value)
            localStorage.setItem("expandCategory", value)
        }
    }

    const formatNaming = (name) => {
        const lCase = name.toLowerCase()
        return lCase.charAt(0).toUpperCase() + lCase.slice(1)

    }

    const capitalLetter = (name) => {
        const words = name.split(' ')
        words[0] = `${words[0][0]}.`
        return words.join(' ')
    }

    const formatLastName = (name) => {
        return `${name.slice(0, 9)}${name.length > 9 ? '..' : ''}`
    }

    const sortByScoring = (teams) => {
        return teams.sort((a, b) => b.scoring - a.scoring)
    }

    return (
        <>
            {
                categories.map((category, key) => {
                    return (
                        <div className="timetables" key={key} id={category.parent.name}>
                            <div aria-hidden="true" className="timetables-categories-title clickable" onClick={() => handleExpandCategory(category.parent.name)}>
                                <h3>{category.parent.name}</h3>
                                {expandCategory === category.parent.name ? <KeyboardArrowUp fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                            </div>
                            <div className={expandCategory === category.parent.name ? "timetables-groups show" : "timetables-groups"}>
                                {category.groups.map((group, key) => {
                                    return (
                                        <div key={key} >
                                            <div className="timetables-group-card-title-container">
                                                <div className="timetables-group-card-title-text">
                                                    {group.name}
                                                </div>
                                            </div>
                                            <div className="timetables-group-card">
                                                {sortByScoring(group.teams).map((team, key) => {
                                                    return (
                                                        <div key={key} className="timetables-group-match">
                                                            <div className="timetables-card-row space-between">
                                                                <div className="flex-column-custom timetables-card-container">
                                                                    <div className="timetables-card-nombre">
                                                                        {
                                                                            `${capitalLetter(formatNaming(team.players[0].name))} ${formatLastName(formatNaming(team.players[0].lastName))}`
                                                                        }
                                                                    </div>
                                                                    <div className="timetables-card-nombre">
                                                                        {
                                                                            `${capitalLetter(formatNaming(team.players[1].name))} ${formatLastName(formatNaming(team.players[1].lastName))}`
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="timetables-card-scoring-container">
                                                                    {
                                                                        key === 0 &&
                                                                        <div className="timetables-card-scoring-icon golden">
                                                                            <EmojiEventsIcon />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        key === 1 &&
                                                                        <div className="timetables-card-scoring-icon silver">
                                                                            <MilitaryTechIcon />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        key === 2 && group.teams.length > 3 &&
                                                                        <div className="timetables-card-scoring-icon bronze">
                                                                            <WorkspacePremiumIcon />
                                                                        </div>
                                                                    }
                                                                    <div className="timetables-card-scoring-score">
                                                                        {team.scoring}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    )
                })

            }

        </>


    );
};

ClasificationGroups.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    categories: PropTypes.array,
};

export default ClasificationGroups;
