import React from "react";
import BlogItemContainer from "../containers/blog/blog-item";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import RegistrationForm from "../components/registration-form";
import "./registration.scss"
const RegistrationPage = ({ config }) => {
    const formStyle = {
        borderRadius: '0px 65px',
        backgroundColor: "#fff"
    }
    const divStyle = {
        backgroundColor: "rgb(25, 45, 77)"
    }
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Inscripciones" />
                <div className="wrapper">
                    <Header config={config} />
                    <PageBanner
                        title="Inscripciones"
                        excerpt="Registrate y disfruta del siguiente torneo. <br />
                        ¡Te garantizamos diversión y mucho pádel!"
                    />
                    <section className="contact-section section-py py-6">
                        <div style={divStyle}>


                            <div className="container">
                                <div className="row mb-n7 " >
                                    <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 mb-7 mt-7 ms-auto me-auto p6" style={formStyle}>
                                        <div className="contact-title-section pb-3 ">
                                            <h3 className="title ">Inscribete</h3>
                                        </div>
                                        <RegistrationForm lastTournament={config} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <Footer config={config} />
                    <ScrollToTop />
                </div>
            </Layout >
        </React.Fragment >
    );
};

RegistrationPage.propTypes = {
    nextTournament: PropTypes.any
};
export default RegistrationPage;
