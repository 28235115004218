import React from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import Images from "../containers/images"
import SEO from "../components/seo";
import PropTypes from "prop-types";

const ImagesPages = ({ config }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Torneos" />
                <div className="wrapper">
                    <Header config={config} />
                    <PageBanner
                        title="Fotografías"
                    />
                    <Images tournamentId={config.nextTournament} />
                    <Footer config={config} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};
ImagesPages.propTypes = {
    config: PropTypes.string
};
export default ImagesPages;
