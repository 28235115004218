import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import Patrocinadores from "../../../data/patrocinadores.json";
import { Carousel } from '@trendyol-js/react-carousel';
import CarouselImg from '../../../components/carousel-image'
const ThankyouContainer = ({ classOption }) => {
    return (
        <div className={`feature-section position-relative ${classOption}`}>
            <div className="container custom-container">
                <div className="row g-0 align-items-center mt-7">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-center mt-7">
                        <SectionTitle
                            classOption="title-section"
                            subTitle=""
                            title="Gracias por <span class='text-primary'>registrarte</span>"
                            excerptClassOption="mb-10"
                            excerpt="Hemos recibido correctament su inscripción al próximo torneo de Dual Padel </br> 
                            En breves recibiréis un correo de confirmación con vuestros datos.</br>
                            Muchas gracias"
                        />
                        {/* <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/about"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

ThankyouContainer.propTypes = {
    classOption: PropTypes.string,
};

ThankyouContainer.defaultProps = {
    classOption: "section-pb",
};

export default ThankyouContainer;
