import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ThankyouContainer from "../containers/global/thankyou";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ThankyouPage = ({ config }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dual Padel – Thank you" />
                <div className="wrapper">
                    <Header config={config} />
                    <ThankyouContainer classOption="section-pt" />
                    <Footer config={config} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ThankyouPage;
