import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
    return (
        <div className={`feature-section position-relative`}>
            <div className="container custom-container">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-left">
                        <SectionTitle
                            classOption="title-section"
                            subTitle="1.- Responsable del tratamiento de los datos"
                            excerptClassOption="mb-5"
                            excerpt='El responsable del tratamiento de los datos es DualPadel que gestiona esta misma web. En adelante, nos referiremos a ella como "DualPadel". Nuestra razón social, dirección y otros datos de contacto están disponibles en la página "Contacto" de nuestra web.'
                        />
                        <SectionTitle
                            classOption="title-section"
                            subTitle="2.- Datos que recopilamos y cómo los usamos"
                            excerptClassOption="mb-5"
                            excerpt='En la web de inscripciones de torneos de tenis, recopilamos los siguientes datos personales:<br/>
                            <br/>
                            - Nombre y apellidos<br/>
                            - Dirección de correo electrónico<br/>
                            - Número de teléfono<br/>
                            - Categoría de torneo<br/>
                            - Talla camiseta<br/>
                            <br/>
                            Estos datos se recopilan a través de los formularios de inscripción que los clientes completan para participar en los torneos de padel que organizamos.<br/>
                            <br/>
                            Utilizamos estos datos exclusivamente para la gestión de los torneos y para enviar información sobre los mismos, incluyendo horarios, resultados, clasificaciones y otros comunicados relacionados con la organización de los torneos.<br/>
                            <br/>
                            Además, podremos utilizar los datos para fines estadísticos internos, a fin de analizar el perfil de nuestros clientes y mejorar nuestros servicios.'
                        />
                        <SectionTitle
                            classOption="title-section"
                            subTitle="3.- Comunicación de los datos a terceros"
                            excerptClassOption="mb-5"
                            excerpt='DualPadel no compartirá tus datos personales con terceros, salvo en los casos en que la ley nos obligue a hacerlo.'
                        />
                        <SectionTitle
                            classOption="title-section"
                            subTitle="4.- Derechos de los interesados"
                            excerptClassOption="mb-5"
                            excerpt='Tienes derecho a acceder, rectificar y suprimir tus datos personales en cualquier momento. Para ello, puedes enviar un correo electrónico a la dirección de contacto que aparece en nuestra web.'
                        />
                        <SectionTitle
                            classOption="title-section"
                            subTitle="5.- Seguridad de los datos"
                            excerptClassOption="mb-5"
                            excerpt='DualPadel ha adoptado medidas de seguridad técnicas y organizativas para proteger tus datos personales de posibles pérdidas, usos indebidos, accesos no autorizados, divulgaciones o alteraciones.'
                        />
                        <SectionTitle
                            classOption="title-section"
                            subTitle="6.- Modificaciones de la política de privacidad"
                            excerptClassOption="mb-5"
                            excerpt='DualPadel se reserva el derecho de modificar esta política de privacidad en cualquier momento. <br/>Las modificaciones serán publicadas en nuestra web y entrarán en vigor en el momento de su publicación.<br/>
                            <br/>
                            Al utilizar nuestra web de inscripciones de torneos padel, aceptas esta política de privacidad y el tratamiento de tus datos personales de acuerdo con lo que se ha indicado en la misma.'
                        />

                        {/* <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/about"
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAboutContainer;
