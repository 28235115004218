import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CarouselImg = ({ data }) => {
    console.log(data)
    const imgStyle = {
        margin: "auto",
        width: "100%",
    }
    return (
        <div className=" d-flex justify-content-center">
            <img style={imgStyle}
                src={process.env.PUBLIC_URL + data.icon}
                alt=" feature logo"
            />
        </div>
    );
};

CarouselImg.propTypes = {
    data: PropTypes.object,
};

export default CarouselImg;
