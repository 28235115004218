import React from "react";
import Intro from "../../../components/intro";
import HomeData from "../../../data/home.json";

const IntroContainer = () => {
    return (
        <div className="section position-relative">
            <img
                className="banner-shape"
                src={process.env.PUBLIC_URL + "/images/banner/shape_dual_padel.png"}
                alt="shape"
            />
            <Intro data={HomeData[0].slider[0]} />
        </div>
    );
};

export default IntroContainer;
