import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const Button = ({ classOption, text, path, style, disabled }) => {
    const classDisabled = {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    }
    console.log(disabled)
    return (
        <React.Fragment>
            {disabled ? <Link style={classDisabled} to={process.env.PUBLIC_URL + path} className={`${classOption}`} > {text} </Link> : <Link style={style} to={process.env.PUBLIC_URL + path} className={`${classOption}`} > {text} </Link>}

        </React.Fragment>
    );
};

Button.propTypes = {
    classOption: PropTypes.string,
    text: PropTypes.string,
    path: PropTypes.string,
    style: PropTypes.any,
    disabled: PropTypes.bool
};
Button.defaultProps = {
    classOption: "btn",
};

export default Button;
