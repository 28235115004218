import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import "./ranking.scss"
const Ranking = ({ title, categories }) => {
    const [expandCategory, setExpandCategory] = useState("")

    useEffect(() => {
        setExpandCategory(localStorage.getItem("expandCategory") || "")
        const element = document.getElementById(localStorage.getItem("expandCategory") || null);
        if (element) {
            const yOffset = -120;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, []);

    const handleExpandCategory = (value) => {
        if (value === expandCategory) {
            setExpandCategory("")
            localStorage.setItem("expandCategory", "")
        } else {
            setExpandCategory(value)
            localStorage.setItem("expandCategory", value)
        }
    }

    const formatNaming = (name) => {
        const lCase = name.toLowerCase()
        return lCase.charAt(0).toUpperCase() + lCase.slice(1)

    }

    const capitalLetter = (name) => {
        const words = name.split(' ')
        words[0] = `${words[0][0]}.`
        return words.join(' ')
    }


    const formatLastName = (name) => {
        const list = name.split(' ')
        const words = list.map((l) => {
            const lCase = l.toLowerCase()
            return lCase.charAt(0).toUpperCase() + lCase.slice(1)
        })
        return words.join(' ')
    }

    const sortByScoring = (players) => {
        return players.sort((a, b) => b.scoring - a.scoring)
    }

    return (
        <>
            {
                categories.map((category, key) => {
                    let position = 0
                    return (
                        <div className="ranking" key={key} id={category.name}>
                            <div aria-hidden="true" className="ranking-categories-title clickable" onClick={() => handleExpandCategory(category.name)}>
                                <h3>{category.name}</h3>
                                {expandCategory === category.name ? <KeyboardArrowUp fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                            </div>
                            <div className={expandCategory === category.name ? "ranking-groups show" : "ranking-groups"}>

                                <div  >
                                    <div className="ranking-group-card-title-container">
                                        <div className="ranking-group-card-title-text">
                                            Ranking
                                        </div>
                                    </div>
                                    <div className="ranking-group-card">
                                        {sortByScoring(category.scoring).map((player, key) => {
                                            if (position == 0) {
                                                position += 1
                                            }
                                            if (key != 0 && player.scoring !== category.scoring[key - 1].scoring) {
                                                position += 1
                                            }
                                            return (
                                                <div key={key} className="ranking-group-match">
                                                    <div className="ranking-card-row">
                                                        <div className="ranking-card-container">
                                                            <div className="ranking-card-position">
                                                                {
                                                                    `# ${position}`
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="ranking-card-container">
                                                            <div className="ranking-card-nombre">
                                                                {
                                                                    `${formatNaming(player.player.name)} ${formatLastName(player.player.lastName)}`
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="ranking-card-scoring-container">
                                                            {/* {
                                                                key === 0 &&
                                                                <div className="ranking-card-scoring-icon golden">
                                                                    <EmojiEventsIcon />
                                                                </div>
                                                            }
                                                            {
                                                                key === 1 &&
                                                                <div className="ranking-card-scoring-icon silver">
                                                                    <MilitaryTechIcon />
                                                                </div>
                                                            }
                                                            {
                                                                key === 2 &&
                                                                <div className="ranking-card-scoring-icon bronze">
                                                                    <WorkspacePremiumIcon />
                                                                </div>
                                                            } */}
                                                            <div className="ranking-card-scoring-score">
                                                                {player.scoring}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>


                            </div>
                        </div>
                    )
                })

            }

        </>


    );
};

Ranking.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    categories: PropTypes.array,
};

export default Ranking;
