import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";

const Intro = ({ data }) => {
    const sceneEl = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    const isMobile = width <= 768;
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();
        window.addEventListener('resize', handleWindowSizeChange);
        console.log(isMobile)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            parallaxInstance.disable();
        }
    });
    return (
        <div className="hero-slider pt-md-0">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="hero-slide-content">
                            <h2
                                style={{ display: 'inline-block' }}
                                className="title animated"
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            ></h2>
                            {isMobile ? <Button disabled={false} style={{ float: "right", marginTop: "45px" }} classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0" text="Horarios" path="/horarios" /> : <></>}

                            {/* <Button
                                classOption="btn btn-lg animated delay2 btn-secondary btn-hover-secondary mb-3 mb-sm-0"
                                text="Book a Meeting"
                                path="/contact"
                            /> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="hero-img scene mb-5 me-5" id="scene" ref={sceneEl}>
                            <div data-depth="0.2">
                                <img
                                    style={{ width: "500px" }}
                                    className="animated me-7"
                                    src={`${process.env.PUBLIC_URL}/${data.image}`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
