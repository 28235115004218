import React from "react";
import BlogData from "../../../data/blog.json";
import NextTournamentsData from "../../../data/next.json"
import SectionTitleTwo from "../../../components/section-title-two";
import BlogGrid from "../../../components/blog";

const HomeBlog = () => {
    return (
        <div className="blog-section section-pb">
            {
                NextTournamentsData.length > 0 &&
                <div className="container">
                    <SectionTitleTwo
                        classOption="title-section mb-lg-10 pb-lg-1"
                        subTitle="Dual Padel"
                        title="Siguientes<span class='text-primary'>Torneos</span>"
                        excerptClassOption="mt-lg-10 pt-lg-3 mb-10 mb-lg-0"
                        excerpt=""
                    />
                    <div className="row mb-n7">
                        {NextTournamentsData &&
                            NextTournamentsData.slice(0, 3).map((single, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col-lg-4 col-sm-6 mb-7"
                                    >
                                        <BlogGrid
                                            classOption="null"
                                            key={key}
                                            data={single}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            }

            <div className="container">
                <SectionTitleTwo
                    classOption="title-section mb-lg-10 pb-lg-1"
                    subTitle="Dual Padel"
                    title="Últimos<span class='text-primary'>Torneos</span>"
                    excerptClassOption="mt-lg-10 pt-lg-3 mb-10 mb-lg-0"
                    excerpt=""
                />
                <div className="row mb-n7">
                    {BlogData &&
                        BlogData.slice(0, 3).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-sm-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="null"
                                        key={key}
                                        data={single}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default HomeBlog;
