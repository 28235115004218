import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";

const Footer = ({ config }) => {
    const style = {
        width: "180px"
    }
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-3"
                                    image={`${process.env.PUBLIC_URL}/images/logo/dualpadel_logo.svg`}
                                />
                                <p >
                                    El circuito de padel ameteur del Maresme.</p>
                                <p >Diversión fuera y dentro de la pista</p>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 col-md-3 col-sm-2 mb-7 offset-xl-1 mt-7">
                            <div className="footer-widget">
                                <h4 className="fs-6">Dual Padel</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/"
                                            }
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/inscripciones"
                                            }
                                        >
                                            Inscripciones
                                        </Link>
                                    </li>
                                    {
                                        config && config.publishTimetables &&
                                        <li>
                                            <Link
                                                className="footer-link"
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/horarios"
                                                }
                                            >
                                                Horarios
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/contact"
                                            }
                                        >
                                            Contacto
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="footer-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/politica-de-privacidad"
                                            }
                                        >
                                            Politica de privacidad
                                        </Link>
                                    </li>
                                    <ul className="footer-social-links">
                                        <li>
                                            <SocialIcon
                                                classOption="footer-social-link"
                                                path="https://www.instagram.com/dual_padel"
                                                icon="icofont-instagram"
                                            />
                                        </li>
                                        <li>
                                            <SocialIcon
                                                classOption="footer-social-link"
                                                path="https://www.whatsapp.com/"
                                                icon="icofont-whatsapp"
                                            />
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </div>

                        <div className="col-6 col-lg-3 col-md-3 col-sm-2 mb-7 mt-7">
                            <div className="footer-widget">
                                <h4 className="fs-6">Contact info</h4>
                                <p className="fs-7">
                                    El Masnou 08320, Barcelona
                                </p>
                                <ul className="address fs-7">
                                    <li className="list-inline-item">
                                        <a
                                            className="address-link"
                                            href="tel:+34685111034"
                                        >
                                            +34 685 111 034
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a
                                            className="address-link"
                                            href="tel:+34622064876"
                                        >
                                            +34 622 064 876
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@dualpadel.com"
                                        >
                                            info@dualpadel.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Dual Padel. Copyright &copy; {new Date().getFullYear()}
                                    , All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
