import PropTypes from "prop-types";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// import optional lightbox plugins
import DownloadIcon from '@mui/icons-material/Download';

import { useEffect, useState, useCallback } from "react";
import "./images.scss"

const ImageListComponent = ({ data }) => {
    const [currentImage, setCurrentImage] = useState(-1);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    console.log(data)
    return (
        <div className="imageListComponent">
            <PhotoAlbum photos={data} layout="rows" targetRowHeight={300} onClick={({ index }) => setCurrentImage(index)} />
            <Lightbox
                slides={data.map((image) => ({
                    ...image.srczoom
                }))}
                open={currentImage >= 0}
                index={currentImage}
                close={() => setCurrentImage(-1)}
                plugins={[Fullscreen, Zoom]}
                toolbar={{
                    buttons: [
                        <div key={0} className="yarl__custom_toolbar">
                            <button
                                className="yarl__button"
                                onClick={() =>
                                    downloadImageWithURLCheck(
                                        imagesRefs.current[updatedImages[currentSlide]._id],
                                        isAdmin,
                                        allowDownload
                                    )
                                }
                            >
                                <DownloadIcon />
                            </button>
                        </div>,
                        "close",
                    ],
                }}
            />
            -
        </div>
    );
};

ImageListComponent.propTypes = {
    data: PropTypes.array,
};

export default ImageListComponent;
