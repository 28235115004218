import { useEffect, useRef } from "react";

import CategoryGroups from "./groups";
import CategoryFinalFrame from './final'
import ClasificationGroups from './classification'
import PropTypes from "prop-types";
import "./horarios.scss"
import axios from 'axios'
import configData from '../../config.json'
import { useState } from "react";

const HorariosContainer = ({ tabIndex, categories }) => {
    return (
        <>

            {
                tabIndex === 0 && categories.length > 0 &&
                <div className="container-groups-horarios">
                    <CategoryGroups categories={categories} />
                </div>
            }
            {

                tabIndex === 1 && categories.length > 0 &&
                <div className="container-groups-horarios">
                    <CategoryFinalFrame categories={categories} />
                </div>
            }
            {
                tabIndex === 2 && categories.length > 0 &&
                <div className="container-groups-horarios">
                    <ClasificationGroups categories={categories} />
                </div>
            }
        </>

    );
};

export default HorariosContainer;

HorariosContainer.propTypes = {
    categories: PropTypes.array,
    category: PropTypes.string,
    tabIndex: PropTypes.number,
    tournament: PropTypes.string,
};
