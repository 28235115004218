import { useEffect, useRef } from "react";

import Ranking from './ranking'
import PropTypes from "prop-types";
import "./ranking.scss"
import axios from 'axios'
import configData from '../../config.json'
import { useState } from "react";

const RankingContainer = ({ categories }) => {

    return (
        <>
            {
                categories.length > 0 &&
                <div className="container-groups">
                    <Ranking categories={categories} />
                </div>
            }
        </>

    );
};

export default RankingContainer;

RankingContainer.propTypes = {
    categories: PropTypes.array,
};
