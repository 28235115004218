import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import ThankyouPage from "./pages/thankyou";
import FaqPage from "./pages/faq";
import BlogPage from "./pages/blog";
import RegistrationPage from "./pages/registration";
import ContactPage from "./pages/contact";
import HorariosPage from "./pages/horarios"
import ImagesPage from "./pages/images"
import RankingPage from "./pages/ranking"
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";
import configData from './config.json'

import { CookieConsent } from "react-cookie-consent";
import axios from "axios";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";


const App = () => {
    const [lastTournament, setLastTournament] = useState(null)

    const getLastTournament = async () => {
        const response = await axios.get(`${configData.apiServer}/tournaments/last`)
        console.log(response.data)
        setLastTournament(response.data)
    }
    useEffect(() => {
        getLastTournament()
    }, [])
    return (
        <>
            {
                lastTournament ?
                    <Router>
                        <CookieConsent>Este sitio web usa cookies para mejorar la experiencia del usuario. Al utilizar nuestro sitio web, usted acepta todas las cookies de acuerdo con nuestra Política de cookies. Más información</CookieConsent>
                        <NavScrollTop>
                            <Switch>
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/"}`}
                                    exact
                                    component={() => <HomePage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/politica-de-privacidad"}`}
                                    component={() => <AboutPage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/thank-you"}`}
                                    component={() => <ThankyouPage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/contact"}`}
                                    component={() => <ContactPage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/tournaments"}`}
                                    component={() => <BlogPage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/inscripciones"}`}
                                    component={() => <RegistrationPage config={lastTournament} />}
                                />
                                {
                                    lastTournament && lastTournament.publicTimetable &&
                                    <Route
                                        path={`${process.env.PUBLIC_URL + "/horarios"}`}
                                        component={() => <HorariosPage tournament={lastTournament} />}
                                    />

                                }
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/ranking"}`}
                                    component={() => <RankingPage config={lastTournament} />}
                                />
                                <Route
                                    path={`${process.env.PUBLIC_URL + "/images"}`}
                                    component={() => <ImagesPage config={lastTournament} />}
                                />
                                <Redirect from="*" to="/" />
                            </Switch>
                        </NavScrollTop>
                    </Router> :
                    <></>
            }
        </>
    );
};

export default App;
