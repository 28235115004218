import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import configData from '../../config.json'
import ImageListComponent from "../../components/images"
import InfiniteScroll from "react-infinite-scroll-component"
import "./images.scss"
const HEIGHT = 300
const WIDTH = 200

const HEIGHT_SET = 1865
const WIDTH_SET = 1243

const ImagesContainer = ({ tournamentId }) => {

    const [images, setImages] = useState([])
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(9)

    const getImages = async () => {
        const response = await axios.get(`${configData.apiServer}/images/${tournamentId}?limit=${limit}&page=${page}`)
        const imagesSized = response.data.images.map(image => {
            return { src: `${image.src}?height=${HEIGHT}&width=${WIDTH}`, height: HEIGHT, width: WIDTH, title: "test", srczoom: { height: HEIGHT_SET, width: WIDTH_SET, src: `${image.src}?height=${HEIGHT_SET}&width=${WIDTH_SET}` } }
        })
        const imagesCopy = [...images]
        setImages(imagesCopy.concat(imagesSized))
    }

    const handleNextPage = () => {
        setPage(page + 1)
    }

    useEffect(() => {
        getImages()
    }, [limit, page]);
    return (
        <InfiniteScroll next={handleNextPage} dataLength={images.length} hasMore={true}>
            <div className="imagesContainer">
                {images.length > 0 && <ImageListComponent data={images} />}
            </div>
        </InfiniteScroll>


    );
};

export default ImagesContainer;

ImagesContainer.propTypes = {
    tournamentId: PropTypes.string,
};
