import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import moment from "moment-timezone";

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const CategoryGroups = ({ title, categories }) => {
    const [expandCategory, setExpandCategory] = useState(false)
    useEffect(() => {
        setExpandCategory(localStorage.getItem("expandCategory") || "")
        const element = document.getElementById(localStorage.getItem("expandCategory") || null);
        if (element) {
            const yOffset = -120;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, []);

    const handleExpandCategory = (value) => {
        if (value === expandCategory) {
            setExpandCategory("")
            localStorage.setItem("expandCategory", "")
        } else {
            setExpandCategory(value)
            localStorage.setItem("expandCategory", value)
        }
    }

    const formatNaming = (name) => {
        const words = name.split(' ')

        const newWords = words.map(word => {
            const lCase = word.toLowerCase()
            return lCase.charAt(0).toUpperCase() + lCase.slice(1)
        })
        return newWords.join(' ')
    }

    const formatPlaceholder = (placeholder) => {
        if (placeholder.includes('Part') || placeholder.includes('Cuart') || placeholder.includes('Semi') || placeholder.includes('Fin')) {
            const words = placeholder.split(' ')
            words[1] = `${words[1].slice(0, 3)}.`
            return words.join(' ')
        } else {
            return placeholder
        }
    }

    const formatLastName = (name) => {
        const words = name.split(' ')
        if (words.length > 1 && words[0].length < 4) {
            return `${name.slice(0, 9)}${name.length > 9 ? '..' : ''}`
        }
        return words[0]
    }

    const sortMatches = (matches) => {
        console.log(matches)
        return matches.sort((a, b) => moment(a.datetime) - moment(b.datetime))
    }

    return (
        <>
            {
                categories.map((category, key) => {
                    return (
                        <div className="timetables" key={key} id={category.parent.name}>
                            <div aria-hidden="true" className="timetables-categories-title clickable" onClick={() => handleExpandCategory(category.parent.name)}>
                                <h3>{category.parent.name}</h3>
                                {expandCategory === category.parent.name ? <KeyboardArrowUp fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                            </div>
                            <div className={expandCategory === category.parent.name ? "timetables-groups show" : "timetables-groups"}>
                                {category.groups.map((group, key) => {
                                    return (
                                        <div key={key} >
                                            <div className="timetables-group-card-title-container">
                                                <div className="timetables-group-card-title-text">
                                                    {group.name}
                                                </div>
                                            </div>
                                            <div className="timetables-group-card">
                                                {sortMatches(group.matches).map((match, key) => {
                                                    console.log(match)
                                                    return (
                                                        <div key={key} className="timetables-group-match">
                                                            <div className="timetables-card-row space-between">
                                                                {
                                                                    match.winner && match.winner === match.teams[0]._id &&
                                                                    <EmojiEventsIcon style={{ color: 'goldenrod' }} />
                                                                }
                                                                <div className="flex-column-custom timetables-card-container">
                                                                    <div className={`timetables-card-nombre ${match.winner && match.winner === match.teams[0]._id && 'bold'}`}>
                                                                        {
                                                                            match.teams && match.teams?.length > 0 && match.teams[0] ?
                                                                                `${match.teams[0].players[0].name.toUpperCase()[0]}. ${formatLastName(formatNaming(match.teams[0].players[0].lastName))}`:
                                                                                formatPlaceholder(match.placeholders[0])
                                                                        }
                                                                    </div>
                                                                    <div className={`timetables-card-nombre ${match.winner && match.winner === match.teams[0]._id && 'bold'}`}>
                                                                        {
                                                                            match.teams && match.teams?.length > 0 && match.teams[0]?
                                                                                `${match.teams[0].players[1].name.toUpperCase()[0]}. ${formatLastName(formatNaming(match.teams[0].players[1].lastName))}`:
                                                                                formatPlaceholder(match.placeholders[0])
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="timetables-card-vs">
                                                                    {"vs"}
                                                                </div>
                                                                <div className="flex-column-custom timetables-card-container">
                                                                <div className={`timetables-card-nombre ${match.winner && match.winner === match.teams[1]._id && 'bold'}`}>
                                                                        {
                                                                            match.teams && match.teams?.length > 0 && match.teams[1]?
                                                                                `${match.teams[1].players[0].name.toUpperCase()[0]}. ${formatLastName(formatNaming(match.teams[1].players[0].lastName))}`:
                                                                                formatPlaceholder(match.placeholders[0])
                                                                        }
                                                                </div>
                                                                <div className={`timetables-card-nombre ${match.winner && match.winner === match.teams[1]._id && 'bold'}`}>
                                                                    {
                                                                        match.teams && match.teams?.length > 0 && match.teams[1]?
                                                                            `${match.teams[1].players[1].name.toUpperCase()[0]}. ${formatLastName(formatNaming(match.teams[1].players[1].lastName))}`:
                                                                            formatPlaceholder(match.placeholders[0])
                                                                    }
                                                                </div>
                                                                </div>
                                                                {
                                                                    match.winner && match.winner === match.teams[1]._id &&
                                                                    <EmojiEventsIcon style={{ color: 'goldenrod' }} />
                                                                }
                                                            </div>
                                                            <div className="timetables-card-row information">
                                                                <div className="timetables-card-horario timetables-card-container">
                                                                    <AccessTimeOutlinedIcon sx={{ marginRight: "4px", marginTop: "-3px" }} fontSize="small" />
                                                                    {moment(match.datetime).tz('Europe/Madrid').format('ddd HH:mm')}
                                                                </div>
                                                                <div className="timetables-card-horario timetables-card-container">
                                                                    {match.courtName}
                                                                </div>
                                                                <div className="timetables-card-horario timetables-card-container">
                                                                    {match.result.length > 0 ? match.result.map(res => {
                                                                        return <div className="result-detail">
                                                                            {res.replace('-', '/')}
                                                                        </div>
                                                                    }) : '--'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    )
                })

            }

        </>


    );
};

CategoryGroups.propTypes = {
    categories: PropTypes.array,
    title: PropTypes.string
};

export default CategoryGroups;
