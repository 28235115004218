import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const ContactPage = ({ config }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Buznex – Contact" />
                <div className="wrapper">
                    <Header config={config} />
                    <PageBanner
                        title="Contacto"
                        excerpt="No dudes en contactar con nosotros. <br /> Resolveremos todas tus dudas"
                    />
                    <ContactContainer />
                    <Footer config={config} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ContactPage;
