import "./blog.scss"
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { slugify } from "../../utils";

const BlogGrid = ({ data, classOption }) => {
    return (
        <div className="blog-card">
            <div className={`thumb bg-light text-center ${classOption}`}>
                {data.registers ? <a href={process.env.PUBLIC_URL + `/inscripciones`}><img src={`${process.env.PUBLIC_URL}/${data.media.gridImage}`} alt="img" /> </a> : <a href={process.env.PUBLIC_URL + `/${data.media.gridImage}`}><img src={`${process.env.PUBLIC_URL}/${data.media.gridImage}`} alt="img" /> </a>}
            </div>
            <div className="blogContent">

                <Link
                    to={
                        process.env.PUBLIC_URL +
                        `/author/${slugify(data.author)}`
                    }
                >
                    <span className="blog-meta author">{data.author}</span>
                </Link>
                <span className="separator">-</span>
                <Link
                    to={`#`}
                >
                    <span className="blog-meta date">{data.date}</span>
                </Link>
                <h3 className="title">
                    <Link
                        to={process.env.PUBLIC_URL + `/${data.media.gridImage}`}
                    >
                        {data.title}
                    </Link>
                </h3>
            </div>
        </div>
    );
};

BlogGrid.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string,
};

BlogGrid.defaultProps = {
    classOption: "thumb",
};

export default BlogGrid;
