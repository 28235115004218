import { NavLink } from "react-router-dom";

const MainMenu = ({ config }) => {
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex float-end">
                <li>
                    <NavLink
                        to={process.env.PUBLIC_URL + "/ranking"}
                        className="btn-lg animated delay1 me-4 mb-3 mb-sm-0"
                    >
                        Ranking
                    </NavLink>
                </li>

                {
                    config && config.publicTimetable &&
                    <li>
                        <NavLink
                            to={process.env.PUBLIC_URL + "/horarios"}
                            className="btn-lg animated delay1 me-4 mb-3 mb-sm-0"
                        >
                            Horarios
                        </NavLink>
                    </li>
                }


                {/* <li>
                    <NavLink
                        to={process.env.PUBLIC_URL + "/images"}
                        className="btn-lg animated delay1 me-4 mb-3 mb-sm-0"
                    >
                        Fotografías
                    </NavLink>
                </li> */}
                <li>
                    <NavLink
                        to={process.env.PUBLIC_URL + "/contact"}
                        className="btn-lg animated delay1 me-4 mb-3 mb-sm-0"
                    >
                        Contacto
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={process.env.PUBLIC_URL + "/inscripciones"}
                        //to={""}
                        className="btn-lg animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                        style={{
                            color: "white",
                        }}
                    // style={{
                    //     color: "rgba(0, 0, 0, 0.26)",
                    //     boxShadow: "none",
                    //     backgroundColor: "rgba(0, 0, 0, 0.12)",
                    // }}
                    >
                        Inscripciones
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
