import React, { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PropTypes from "prop-types";

import RankingContainer from "../containers/ranking"
import axios from "axios";
import configData from '../config.json'

const RankingPage = ({ config }) => {
    const [categories, setCategories] = useState([])

    const buildScoring = (data) => {
        const categories = [...new Set(data.map(({ category }) => category))]
        const scoring = []
        categories.forEach(category => {
            scoring.push({
                name: category,
                scoring: []
            })
        })

        data.forEach(score => {
            scoring.forEach(scoreCategory => {
                if (scoreCategory.name == score.category) {
                    scoreCategory.scoring.push(score)
                }
            })

        })

        return scoring


    }
    const getCategories = async () => {
        const response = await axios.post(`${configData.apiServer}/scoring/find`, {search: {season: "2024"}})
        const scoring = buildScoring(response.data)
        console.log(scoring)
        const mascCat = scoring.filter((category) => category.name.toLowerCase().includes('masc')).sort((a, b) => {
            if (a.name.includes('+') || b.name.includes('+')) {
                if (a.name.includes(b.name) || b.name.includes(a.name)) {
                    return -1
                }
            }
            return (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0
        })
        const femCat = scoring.filter((category) => category.name.toLowerCase().includes('fem')).sort((a, b) => {
            if (a.name.includes('+') || b.name.includes('+')) {
                if (a.name.includes(b.name) || b.name.includes(a.name)) {
                    return -1
                }
            }
            return (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0
        })
        const mixtCat = scoring.filter((category) => category.name.toLowerCase().includes('mixt')).sort((a, b) => {
            if (a.name.includes('+') || b.name.includes('+')) {
                if (a.name.includes(b.name) || b.name.includes(a.name)) {
                    return -1
                }
            }
            return (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0
        })
        let categoriesList = femCat.concat(mascCat).concat(mixtCat)
        setCategories(categoriesList)
    }
    useEffect(async () => {
        getCategories()
    }, [])
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ranking" />
                <div className="wrapper">
                    <Header config={config} />
                    <div className="" style={{ background: "rgba(#192D4D,.5)" }}>
                        <RankingContainer categories={categories}></RankingContainer>
                    </div>
                    <Footer config={config} />
                    <ScrollToTop />
                </div>
            </Layout >
        </React.Fragment >
    );
};
RankingPage.propTypes = {
    config: PropTypes.string
};
export default RankingPage;
