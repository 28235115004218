import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import Patrocinadores from "../../../data/patrocinadores.json";
import { Carousel } from '@trendyol-js/react-carousel';
import CarouselImg from '../../../components/carousel-image'
const IconBoxContainer = ({ classOption }) => {
    return (
        <div className={`feature-section position-relative ${classOption}`}>
            <div className="container custom-container">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-6 col-lg-8 mx-auto mb-3 text-center">
                        <SectionTitle
                            classOption="title-section"
                            subTitle=""
                            title="Sobre <span class='text-primary'>nosotros</span>"
                            excerptClassOption="mb-10"
                            excerpt="Dual Padel nace de la unión de dos personas apasionadas del deporte como es el Pádel. </br> 
                            Su principal objetivo es profesionalizar la categoria amateur. </br> Manteniendo una relación muy cercana entre diversión y entrenimiento."
                        />
                        {/* <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/about"
                        /> */}
                    </div>

                    <div className="col-xl-12 col-lg-12 mx-auto text-center">
                        <SectionTitle
                            classOption="title-section"
                            subTitle="Patrocinadores"
                            title=""
                            excerpt=""
                        />
                        {/* <Button
                            classOption="btn btn-lg btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/about"
                        /> */}
                    </div>
                    <Carousel className="d-flex" show={3.5} slide={3} swiping={true}>
                        {Patrocinadores.imgBox &&
                            Patrocinadores.imgBox.map((single, key) => {
                                return <CarouselImg key={key} data={single} />;
                            })}
                    </Carousel>


                </div>
            </div>
        </div>
    );
};

IconBoxContainer.propTypes = {
    classOption: PropTypes.string,
};

IconBoxContainer.defaultProps = {
    classOption: "section-pb",
};

export default IconBoxContainer;
